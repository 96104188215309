import React, { useState } from 'react'
import { UilBars,UilMultiply } from '@iconscout/react-unicons'
import { useStore } from '../../../hooks/useStore.jsx'
import { useApi } from '../../../hooks/useApi.jsx'
import { useDeviceInfo } from '../../../hooks/useDeviceInfo.jsx'
import logo from '../../../assets/img/gti-circle.svg'
import './header.styles.scss'
import { useEffect } from 'react'

export function Header(){

  const { deviceSize } = useDeviceInfo()
  const [store,dispatch] = useStore()
  const [logOutRes, execLogOut] = useApi('logout')
  const [mobileMenuExpanded,setMobileMenuExpanded] = useState(false)

  useEffect( () => {
    if ( logOutRes.isSuccess ){
      dispatch({type:'setUser', payload:{user:null}})
    }
  }, [logOutRes])

  return (
    <header className={deviceSize}>
      <div className="container">
        <div className="brand">
          <img src={logo} alt="GTI Tours" />
        </div>
        <div className="menu">
          <div className="globalMenu">
            {/* <div className="hideOnMobile">{isConnected?'CONNECTED':'DISCONNECTED'}</div> */}
            <div className="hideOnMobile menuItemText"><div>Questions? Call <a href="tel:616-396-1234">(616) 396-1234</a></div></div>
            {
              store.user.email
              ? <div class="menuItem"><div>Hi, {store.user.first_name} <button onClick={execLogOut}>Logout</button></div></div>
              : <div class="menuItem"><div>Sign In</div></div>
            }
          </div>
          {
              store.user.email && 
              <div className="localMenu hideOnMobile">
                {/* <div>My Account</div>
                <div>My Trips</div> */}
              </div>
          }
          <div className="mobileMenu hideOnTablet hideOnDesktop">
            {
              mobileMenuExpanded
              ? <UilMultiply size="40" color="#222222" className="pointer" onClick={ () => setMobileMenuExpanded(b=>!b) } />
              : <UilBars size="40" color="#222222" className="pointer" onClick={ () => setMobileMenuExpanded(b=>!b) } />
            }
          </div>
        </div>
      </div>
      <div className={`mobileMenuContents ${mobileMenuExpanded?'expand':''}`}>
        <div>My Account</div>
        <div>My Trips</div>
      </div>
    </header>
  )
}