import React, {useEffect, useState} from 'react'
import {axios} from '../api/axios.js'
import Pusher from 'pusher-js';

window.Pusher = Pusher;

// default is to immediately run handshake, pass false to manually exec
export function useSessionHandshake(autoRun=true){

  const [handshake,setHandshake] = useState(false)

  async function doHandshake(){
    axios
    .get(`/api/handshake`)
    .then(({data}) => {
      console.log('handshake data: ', data)
      // use set timeout if you don't like the flash
      // setTimeout( () => {
        setHandshake(data)
      // }, 1500)
    })
    .catch( err => {
      console.log({err})
    });
  }

  useEffect( () => {
    if ( autoRun ){
      doHandshake()
    }
  }, [autoRun])

  return [handshake, doHandshake]

}