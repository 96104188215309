import { axios } from "../api/axios.js";

export const login = async (credentials) => {
  return axios.post(`/login`, credentials)
}

export const logout = async () => {
  return axios.post(`/logout`, {})
}

export const itineraryList = async () => {
  return axios.get('/api/itinerary')
}

export const itinerary = async (id) => {
  return axios.get(`/api/itinerary/${id}`)
}

export const storePayment = async (data) => {
  return axios.post(`/api/payment`,data)
}