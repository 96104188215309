import React, {useEffect, useRef, useState} from 'react'
import {axios} from '../api/axios.js'
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import md5 from '../utility/md5.js'

window.Pusher = Pusher;

const PUSHER_STATUSES = {
  CONNECTED: ''
}

export function usePusherChannels(platformHandshake){

  const LaravelEcho = useRef(null)

  let [isConnected,setIsConnected] = useState(false)

  const {
    csrfToken
  } = platformHandshake

  const rooms = []

  function join(channel,event){
    if ( ! rooms.includes(`${channel}:${event}`) ){

    }
  }

  function leave(channel,event){

  }

  useEffect( () => {

    if ( ! csrfToken ) return

    if ( LaravelEcho.current !== null ) return

    if ( csrfToken && LaravelEcho.current === null ){

      LaravelEcho.current = new Echo({
        broadcaster: 'pusher',
        key: import.meta.env.VITE_PUSHER_APP_KEY,
        // cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
        wsHost: `${hostname}`,
        wsPort: 6001,
        wssPort: 6001,
        forceTLS: true,
        encrypted: true,
        disableStats: true,
        enabledTransports: ['ws','wss'],
        // csrfToken,
        authorizer,
      });

      LaravelEcho.current.connector.pusher.connection.bind('state_change', ({current,previous}) => {
        console.log('STATE CHANGE', {current,previous})
        if ( current === PUSHER_STATUSES.CONNECTED ){
          setIsConnected(true)
        } else {
          setIsConnected(false)
        }
      });
      
      // LaravelEcho.current.channel(`platform`)
      // .listen('.platform.status', (e) => {
      //     console.log("platform",e);
      // });

      LaravelEcho.current.channel(`platform`)
      .listen('.platform.status', (e) => {
          console.log("platform",e);
      });

      const eid = md5('chester@chetmac.com')
      LaravelEcho.current.private(`user.${eid}`)
      .listen('.user.update', (e) => {
          console.log("user",e);
      });

      return () => {
        console.log('Shutting down laravel echo...')
        LaravelEcho.current.channel(`platform`).stopListening('.platform.status')
        LaravelEcho.current.private(`user`).stopListening('.user.update')
        LaravelEcho.current.leaveChannel(`platform`);
      }

    }

  }, [csrfToken])

  return [join,leave,isConnected]

}

// manually specify authorizer so that axios 'credentials' 
// and session/cookie is sent with request.
function authorizer(channel, options){

  function authorize(socketId, callback){

    const payload = {
      socket_id: socketId,
      channel_name: channel.name
    }

    axios
    .post(`/api/broadcasting/auth`, payload)
    .then(response => {
      callback(null, response.data);
    })
    .catch(error => {
      callback(error);
    });

  }

  return { authorize }

}