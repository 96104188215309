import React, { useState,useEffect,useRef } from 'react'
import { toast } from 'react-toastify';
import { useDeviceInfo } from '../../../hooks/useDeviceInfo.jsx'
import { useAcceptJs } from 'react-acceptjs'
import { parseISO,format } from 'date-fns'
import { useForm, useWatch } from 'react-hook-form'
import * as formatters from '../../../utils/formatters.js'
import { useApi } from '../../../hooks/useApi'
import { Link, useNavigate, useParams } from 'react-router-dom'
import './Itinerary.styles.scss'

export function Itinerary(){

  const {itineraryId} = useParams()

  const navigate = useNavigate()
  const {deviceSize} = useDeviceInfo()
  const [itineraryRes,getItinerary] = useApi('itinerary')
  const itinerary = itineraryRes.data?.itinerary ?? null

  useEffect( () => {
    getItinerary(itineraryId)
  }, [itineraryId])

  if ( itineraryRes.isLoading ) return <div className="container">Loading...</div>
  if ( ! itinerary ) return <div className="container">404...</div>

  return (
    <div className="container">
      {/* <div><Link to="/">Return to Dashboard</Link></div> */}
      <h2>Itinerary Payment Form</h2>
      <div className="grid">
        <div>
          <h3 className="title">Overview</h3>
          <div className="gti-box fullHeight">

            <div className="detail">
              <span>{itinerary.trip.name}</span>
              <p>{formatters.dateRange(itinerary.trip.departure_date,itinerary.trip.return_date)}</p>
            </div>

            <div className="grid">
            <div className="detail">
              <span>Total Cost</span>
              <p>{ formatters.money(itinerary.total_cost) }</p>
            </div>
            <div className="detail">
              <span>Total Paid</span>
              <p>{ formatters.money(itinerary.total_paid) }</p>
            </div>
            <div className="detail">
              <span>Remaining Balance</span>
              <p>{ formatters.money(itinerary.balance_due) }</p>
            </div>
            </div>
            {
              itinerary.transaction_fees.length > 0 &&
              <p><em>Includes {formatters.money(itinerary.transaction_fees.reduce( (sum,trans) => sum+parseFloat(trans.amount),0))} in transaction fees.</em></p>
            }
          </div>
        </div>
        <div>
          <h3 className="title">Participants</h3>
          <div className="gti-box fullHeight">
            {
            itinerary.registrations.map( r => (
              <div key={r.id} className="detail">
                <span>{r.first_name} {r.last_name}</span>
                <p>{formatters.money(r.total_cost)}</p>
              </div>
            ))
            }
          </div>
        </div>
      </div>

      <div className="grid">
        <div>
          <h3 className="title">Payment Schedule</h3>
          <div className="gti-box fullHeight">
            {
              itinerary.trip.payment_deadlines.map( (pmnt,idx) => (
                <div key={idx} className="detail">
                  <span>
                     {pmnt.label } due on { pmnt.payment_type === 'deposit' ? 'registration' : formatters.date(pmnt.due_date)}
                  </span>
                  <p>
                    {
                      pmnt.payment_type === 'finalpayment'
                      ? 'Remaining Balance'
                      : formatters.money(pmnt.amount)
                    }
                  </p>
                </div>
              ))
            }
          </div>
        </div>
        <div>
          <h3 className="title">Payment History</h3>
        <div className="gti-box fullHeight">
            <History entries={[...itinerary.payments,...itinerary.notes]} />
        </div>
        </div>
      </div>

      {
        (itinerary.balance_due > 0) && <MakeAPayment getItinerary={getItinerary} itinerary={itinerary} />
      }
    </div>
  )
}

function History({entries}){
  const compare = ( e1, e2 ) => {
    let a = e1.transaction_date ?? e1.created_at;
    let b = e2.transaction_date ?? e2.created_at;
    if ( a === b ) return 0;
    return a < b ? -1 : 1
  }
  entries.sort(compare)
  return entries.map( e => {

    if ( e.payment_type ){
      return (
        <div key={e.id} className="detail">
          <span>{formatters.date(e.transaction_date)} &bull; {e.payment_type} &bull; {e.id}</span>
          <p>{formatters.money(e.amount)}</p>
        </div>
      )
    } else if ( e.title ) {
      return (
        <div key={e.created_at} className="detail">
          <span>{formatters.date(e.created_at)} &bull; {e.title}</span>
          <p>{e.body}</p>
        </div>
      )
    }

  })
}

function MakeAPayment({itinerary,getItinerary}){

  const [storePaymentRes,storePayment] = useApi('storePayment')

  const submitNotification = useRef()

  const defaultValues = {
    paymentMethod: 'ach',
    paymentAmount: 0,
    country: 'USA'
  }

  const { register, handleSubmit, setValue, getValues, setError, watch, control, reset, formState: { errors } } = useForm({defaultValues});

  const fees = {
    credit: parseFloat(itinerary.trip.transaction_fee)/100,
    ach: 0,
    check: parseFloat(itinerary.trip.check_fee)
  }

  const balance_info = itinerary.balance_info;

  const [paymentAmountCustomDisplay, setPaymentAmountCustomDisplay] = useState('none')
  const [fee,setFee] = useState(0)
  const [pending,setPending] = useState(false)

  const fieldMap = {
    "E_WC_05": 'cardNumber',
    "E_WC_06": 'expMonth',
    "E_WC_07": 'expYear',
    "E_WC_15": 'cardCode',
  }

  const paymentAmountName = watch('paymentAmountName')
  const paymentMethod = watch('paymentMethod')
  const paymentAmountCustom = watch('paymentAmountCustom')

  function onCustomAmountChange(e){
    const name = getValues('paymentAmountName')
    if ( name === 'custom' ){
      setValue('paymentAmount',e.target.value)
    }
  }

  const onSubmit = async (formData) => {

    if ( paymentMethod === 'credit' ) {
      let depositAmount = parseFloat(itinerary.trip.payment_deadlines[0].amount ?? 0) * itinerary.registrations.length
      let depositFee = 1 + (parseFloat(itinerary.trip.transaction_fee) / 100)
      let limit = depositAmount * depositFee;
      // console.log(limit, itinerary.total_paid, formData.paymentAmount, itinerary)
      if (itinerary.total_paid >= limit) {
        setError('paymentMethod', {
          type: 'custom',
          message: `Credit card enabled for deposit payments only. Please select another payment method.`
        })
        return;
      } else if (itinerary.total_paid + formData.paymentAmount > limit) {
        setError('paymentMethod', {
          type: 'custom',
          message: `Credit card enabled for deposit payments only. Please select another payment method or reduce payment amount.`
        })
        return;
      }
    }

    setPending(true)
    submitNotification.current = toast.info('Securely processing request.', {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    if ( paymentMethod === 'check' ){
      processCheckPayment(formData);
    } else if ( paymentMethod === 'credit' ) {
      processCreditCardPayment(formData);
    } else if ( paymentMethod === 'ach' ) {
      processAchPayment(formData);
    }
  }



  const processCreditCardPayment = async (formData) => {

    setPending(true);
    const cardData = {
      ccnumber: formData.cardNumber,
      cvv: formData.cardCode,
      month: formData.expMonth,
      year: formData.expYear
    };

    try {
      // Clean up sensitive data
      const formDataCopy = { ...cardData, ...formData };
      formDataCopy.fee = fee;

      // Store payment details with the response from NMI
      let res = await storePayment({itineraryId: itinerary.id, formData: formDataCopy});
      console.log('NMI payment response: ', res);

      // if storePayment fails, we don't want to reset the form... do we?
      // how would that happen, most of the fields are simply required without
      // any other validation.
    } catch (error) {
      console.error('NMI payment error:', error);
    } finally {
      setPending(false);
    }
  };

  const processCheckPayment = async (formData) => {
    formData.fee = fee
    storePayment({itineraryId:itinerary.id,formData})
  }

  const processAchPayment = async (formData) => {
    formData.fee = fee
    storePayment({itineraryId:itinerary.id,formData})
  }

  function SubmitButton()
  {

    let paymentAmount = watch('paymentAmount',0)
    if ( isNaN(paymentAmount) ){
      paymentAmount = 0
    }

    let verb = paymentMethod === 'check' ? 'Mail a check for' : 'Pay'

    return (
        <>
          <button
              type="submit"
              disabled={pending || !paymentAmount}
          >{verb} {formatters.money(paymentAmount)} Now
          </button>
          {
              fee > 0 && <div><em>Includes {formatters.money(fee)} non-refundable credit card processor convenience fee.</em></div>
          }
        </>
    )
  }

  useEffect( () => {
    //getMerchantDetails()
  }, [])

  useEffect( () => {

    // UI: Only show custom input field when radio option is selected
    setPaymentAmountCustomDisplay(paymentAmountName === 'custom' ? 'inline' : 'none')

  }, [paymentAmountName])

  useEffect( () => {
    // for reference: balance_info = { nextDueDate, nextDueAmount, nextDueIsPast, totalDueAmount, totalPaidAmount, totalCostAmount }
    let amount = calculateAmount({paymentAmountName, paymentAmountCustom, balance_info})
    let fee = calculateFee({amount, fees, paymentMethod})
    setFee(fee)
    setValue('paymentAmount', amount + fee)
  }, [paymentAmountName, paymentAmountCustom, paymentMethod, balance_info])

  useEffect( () => {
    if ( storePaymentRes.isSuccess ){
      setPending(false);
      reset();
      if ( submitNotification.current ){
        toast.dismiss(submitNotification.current)
      }
      toast.success('Success! Thank you.', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      getItinerary(itinerary.id)
    } else if ( storePaymentRes.error.errors.length ){
      // TODO: handle react-hook-form errors
      alert("Form Errors. Sorry, please try again.");
    }
  }, [storePaymentRes])

  return (
    <>
    <h3 className="title">Make Payment</h3>
    <div className="gti-box">
      <form onSubmit={handleSubmit(onSubmit)} disabled={pending}>

        <div className="grid">
          <div className="formGroup">
            <label>Payment Amount</label>
            {
              (balance_info.nextDueAmount > 0 && balance_info.nextDueIsPast ) &&
              <div className="inputGroup">
                <input
                  type='radio'
                  value='due'
                  {...register('paymentAmountName',{ required:true } )}
                  id='paymentAmount-due'
                />
                <label htmlFor='paymentAmount-due'>Amount Due Now: {formatters.money(balance_info.nextDueAmount)}</label>
              </div>
            }
            {
              (balance_info.nextDueAmount > 0 && ! balance_info.nextDueIsPast ) &&
              <div className="inputGroup">
                  <input
                    type='radio'
                    value='next'
                    {...register('paymentAmountName',{ required:true } )}
                    id='paymentAmount-next'
                  />
                <label htmlFor='paymentAmount-next'>Next Payment Due: {formatters.money(balance_info.nextDueAmount)}</label>
              </div>
            }
            <div className="inputGroup">
            <input
                  type='radio'
                  value='payoff'
                  {...register('paymentAmountName',{ required:true } )}
                  id='paymentAmount-payoff'
                />
              <label htmlFor='paymentAmount-payoff'>Remaining Balance: {formatters.money(balance_info.totalDueAmount)}</label>
            </div>
            <div className="inputGroup">
              <input {...register('paymentAmountName')} type='radio' id='paymentAmount-custom' value='custom' />
              <label htmlFor='paymentAmount-custom'>Other</label>
              <input
                {...register('paymentAmountCustom', { onChange: onCustomAmountChange, required:true }) }
                type="number"
                defaultValue={0}
                step="0.01"
                style={{marginLeft:"10px",display:paymentAmountCustomDisplay,lineHeight:".5em",padding:"2px"}}
              />
              <input {...register('paymentAmount')} type="number" step="0.01" defaultValue={0} style={{marginLeft:"10px",display:'none'}}  />
            </div>
          </div>

          <div className="formGroup">
            <label>Payment Method</label>
            <div className="inputGroup">
              <input {...register('paymentMethod')} type='radio' id='paymentMethod-ach' name='paymentMethod' value='ach' />
              <label htmlFor='paymentMethod-ach'>ACH Bank Transfer</label>
            </div>
            <div className="inputGroup">
              <input {...register('paymentMethod')} type='radio' id='paymentMethod-credit' name='paymentMethod' value='credit' />
              <label htmlFor='paymentMethod-credit'>Credit Card <em>{ itinerary.trip.transaction_fee ? `(${itinerary.trip.transaction_fee}% non-refundable credit card processor convenience fee)` : null }</em></label>
            </div>
            <div className="inputGroup">
              <input {...register('paymentMethod')} type='radio' id='paymentMethod-check' name='paymentMethod' value='check' />
              <label htmlFor='paymentMethod-check'>Mail a check <em>{ itinerary.trip.check_fee ? `($${itinerary.trip.check_fee} fee)` : null }</em></label>
            </div>
            {errors.paymentMethod && <p className='formError'>{errors.paymentMethod.message || `This field is required.`}</p>}
            {
              paymentMethod === 'check' && <p>The payment will be reflected in your account when we receive the check.</p>
            }
          </div>
        </div>
        {/* <div className="pillBar">
          <button type="button" className={`${paymentMethod==='credit'?'selected':''}`} onClick={ () => setPaymentMethod('credit')}>Credit Card</button>
          <button type="button" className={`${paymentMethod==='ach'?'selected':''}`} onClick={ () => setPaymentMethod('ach')}>ACH Bank Transfer</button>
        </div> */}
        { paymentMethod === 'credit' && <CreditFields register={register} errors={errors} />}
        { paymentMethod === 'ach' && <AchFields register={register} errors={errors} />}
        {
        paymentMethod === 'check' &&
        <div style={{whiteSpace: "pre-line", backgroundColor: "#E8E8E8", padding:"10px", marginBottom:"30px"}}>
          {
          `Please send a check to:
          GTI TOURS
          513 East 8th Street
          Suite 13
          Holland, Michigan 49423, USA

          Please make your check payable to GTI TOURS

          If you have any questions or need any other assistance we encourage you to call GTI Tours at (800) 829-8234.
        `}
        </div>
        }
        <SubmitButton />
        {/* <FeeNotice fee={fee} fees={fees} paymentMethod={paymentMethod} /> */}
      </form>
    </div>
    </>
  )
}

function CreditFields({register,errors}){
  return (
    <>
    <h3>Credit Card </h3>
    <div className="grid">
      <div className="formGroup">
        <label>First Name</label>
        <input type="text" {...register('first_name', {required:true})} />
        { errors.first_name && <p className='formError'>{ errors.first_name.message || `This field is required.`}</p> }
      </div>
      <div className="formGroup">
        <label>Last Name</label>
        <input type="text" {...register('last_name', {required:true})} />
        { errors.last_name && <p className='formError'>{ errors.last_name.message || `This field is required.`}</p> }
      </div>
    </div>
    <div className="grid">
      <div className="formGroup">
        <label>Address</label>
        <input type="text" {...register('address', {required:true})} />
        { errors.address && <p className='formError'>{ errors.address.message || `This field is required.`}</p> }
      </div>
      <div>
        <div className="grid">
          <div className="formGroup">
            <label>City</label>
            <input type="text" {...register('city', {required:true})} />
          </div>
          <div className="formGroup">
            <label>State</label>
            <input type="text" {...register('state', {required:true})} />
          </div>
          <div className="formGroup">
            <label>Zip</label>
            <input type="text" {...register('zip', {required:true})} />
          </div>
          <div className="formGroup">
            <label>Country</label>
            <input type="text" {...register('country', {required:true})} />
          </div>
        </div>
        <div>
          { errors.city && <p className='formError'>{ errors.city.message || `City field is required.`}</p> }
          { errors.state && <p className='formError'>{ errors.state.message || `State field is required.`}</p> }
          { errors.zip && <p className='formError'>{ errors.zip.message || `Zip field is required.`}</p> }
          { errors.country && <p className='formError'>{ errors.country.message || `Country field is required.`}</p> }
      </div>
      </div>
    </div>
    <div className="grid">
      <div className="formGroup">
        <label>Card Number</label>
        <input type="text" {...register('cardNumber', {required:true})} />
        { errors.cardNumber && <p className='formError'>{ errors.cardNumber.message || `This field is required.`}</p> }
      </div>
      <div>
        <div className="grid">
          <div className="formGroup">
            <label>Card Security Code</label>
            <input type="text" maxLength="4" {...register('cardCode', {required:true})} />
            { errors.cardCode && <p className='formError'>{ errors.cardCode.message || `This field is required.`}</p> }
          </div>
          <div className="formGroup">
            <label>Expiration Month</label>
            <input type="text" maxLength="2" placeholder="MM" {...register('expMonth', {required:true})} />
            { errors.expMonth && <p className='formError'>{ errors.expMonth.message || `This field is required.`}</p> }
          </div>
          <div className="formGroup">
            <label>Expiration Year</label>
            <input type="text" maxLength="2" placeholder="YY" {...register('expYear', {required:true})} />
            { errors.expYear && <p className='formError'>{ errors.expYear.message || `This field is required.`}</p> }
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

function AchFields({register,errors}){
  return (
    <>
    <h3>ACH Bank Transfer </h3>
    <div className="grid">
      <div className="formGroup">
        <label>Account Type</label>
        <select {...register('account_type', {required:true})}>
          <option value=''>Select Account Type</option>
          <option value='Checking'>Checking</option>
          <option value='Savings'>Saving</option>
        </select>
        { errors.account_type && <p className='formError'>{ errors.account_type.message || `This field is required.`}</p> }
      </div>
      <div className="formGroup">
        <label>Account holder's name</label>
        <input type="text" {...register('account_name', {required:true})} />
        { errors.account_name && <p className='formError'>{ errors.account_name.message || `This field is required.`}</p> }
      </div>
    </div>
    <div className="grid">
      <div className="formGroup">
        <label>Routing Number</label>
        <input type="text" minLength={9} {...register('routing_number', {required:true})} />
        { errors.routing_number && <p className='formError'>{ errors.routing_number.message || `This field is required.`}</p> }
      </div>
      <div className="formGroup">
        <label>Account Number</label>
        <input type="text" {...register('account_number', {required:true})} />
        { errors.account_number && <p className='formError'>{ errors.account_number.message || `This field is required.`}</p> }
      </div>
    </div>
    </>
  )
}

function FeeNotice({fee,fees,paymentMethod}){

  if ( ! fees[paymentMethod] ) return null

  let displayFee = fees[paymentMethod] >= 1
      ? formatters.money(fee)
      : `${fees[paymentMethod]*100}%`

  return (
    <div>
      <em style={{color:"red"}}>Please note that there is a {displayFee} transaction fee for this payment method.</em>
    </div>
  )

}

function calculateAmount({paymentAmountName, paymentAmountCustom, balance_info}){
  let amount = 0
  switch(paymentAmountName){
    case "custom":
      return parseFloat(paymentAmountCustom ?? 0)
    case "next":
    case "due":
      return balance_info.nextDueAmount ?? 0
    case "payoff":
      return balance_info.totalDueAmount ?? 0
  }
}
function calculateFee({fees,paymentMethod,amount}){
  if ( ! paymentMethod ) return 0
  if ( ! fees[paymentMethod] ) return 0
  let fee = fees[paymentMethod] ?? 0
  return fee > 1 ? fee : Math.round(amount*fee*100)/100
}