import Axios from 'axios'

const protocol = window.location.protocol
const hostname = import.meta.env.VITE_API_DOMAIN
const baseURL = `${protocol}//${hostname}`

export const axios = Axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    "Accept": "application/json",
    "Content-Type": "application/json;charset=UTF-8",
    "X-Requested-With": "XMLHttpRequest",
  }
})

// Add a request interceptor for CSRF token
axios.interceptors.request.use(config => {
  // Get token from cookies
  const token = document.cookie
      .split('; ')
      .find(row => row.startsWith('XSRF-TOKEN='))
      ?.split('=')[1];

  if (token) {
    config.headers['X-XSRF-TOKEN'] = decodeURIComponent(token);
  }

  return config;
});