import React, { createContext, useContext, useReducer } from 'react'

let StoreContext = createContext()

export function StoreProvider({ children }) {
  let value = useStoreProvider()
  return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
}

export function useStore() {
  return useContext(StoreContext);
}

const initialState = {
  user: { email: null }
}

function useStoreProvider(){

  const [state, dispatch] = useReducer( reducer, initialState )
  return [state,dispatch]
}

function reducer(state, action){

  const {type,payload} = action

  switch (type){
    case "setUser":
      const user = payload.user ?? { email: null }
      console.log("Setting user to "+user.email)
      return { ...state, user}
    break;
    case "setTripData":
      return { ...state, tripData: payload.tripData}
    break;
    case "setMerchantData":
      return { ...state, merchantData: payload.merchantData}
    break;
    default:
      console.log(`Unknown reducer action ${type}`)
      return state
  }

}