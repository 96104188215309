import React, { useEffect } from 'react'
import { useDeviceInfo } from '../../../hooks/useDeviceInfo.jsx'
import { parseISO,format } from 'date-fns'
import { useApi } from '../../../hooks/useApi'
import { Link, useNavigate } from 'react-router-dom'
import './ItineraryList.styles.scss'
import * as formatters from "../../../utils/formatters.js";

export function ItineraryList(){

  const navigate = useNavigate()
  const {deviceSize} = useDeviceInfo()
  const [listRes,getList] = useApi('itineraryList')
  const list = listRes.data?.itineraryList ?? []

  useEffect( () => {
    getList()
  }, [])

  useEffect( () => {
    // if ( listRes.isSuccess ){
    //   console.log(listRes.data)
    // }
  }, [listRes])
  
  if ( listRes.isLoading ) return <div className="container">Loading...</div>

  return (
    <div className="container">
      <h2>My Upcoming Tours</h2>
      <div className={`${deviceSize} gti-box tripList`}>
          {
            list.map( itinerary => (
              <div key={itinerary.id} className="tripItem">
                <div>
                  <strong>{itinerary.trip.name}</strong>
                  <p>{formatters.dateRange(itinerary.trip.departure_date,itinerary.trip.return_date)}</p>
                </div>
                <div><button onClick={ () => navigate(`/itinerary/${itinerary.id}`)}>Manage</button></div>
              </div>
            ))
          }
      </div>
    </div>
  )
}