import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from "react-router-dom";
import App from './App'
import { StoreProvider } from './hooks/useStore.jsx'
import { DeviceInfoProvider } from './hooks/useDeviceInfo.jsx';
import './index.scss'

import './assets/fonts/Open_Sans/OpenSans/OpenSans-Medium.ttf'
import './assets/fonts/Open_Sans/OpenSans/OpenSans-MediumItalic.ttf'
import './assets/fonts/Open_Sans/OpenSans/OpenSans-Regular.ttf'
import './assets/fonts/Open_Sans/OpenSans/OpenSans-Bold.ttf'

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <BrowserRouter basename="/">
      <StoreProvider>
        <DeviceInfoProvider>
          <App />
        </DeviceInfoProvider>
      </StoreProvider>
    </BrowserRouter>
  </React.StrictMode>
)