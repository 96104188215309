import React, { createContext, useContext, useState, useEffect } from 'react'
import useMediaQuery from './useMediaQuery.jsx'

let DeviceInfoContext = createContext()

export function DeviceInfoProvider({ children }) {
  let value = useDeviceInfoProvider()
  return <DeviceInfoContext.Provider value={value}>{children}</DeviceInfoContext.Provider>;
}

export function useDeviceInfo() {
  return useContext(DeviceInfoContext);
}

function useDeviceInfoProvider(){

  let breaks = [768,1050]

  const [isMobile] = useMediaQuery(`screen and (min-width: 0px) and (max-width: ${breaks[0]-1}px)`)
  const [isTablet] = useMediaQuery(`screen and (min-width: ${breaks[0]}px) and (max-width: ${breaks[1]-1}px)`)
  const [isDesktop] = useMediaQuery(`screen and (min-width: ${breaks[1]}px`)

  const [deviceSize,setDeviceSize] = useState(whichDeviceSize(isMobile,isTablet,isDesktop))

  const [isTouchScreen] = useMediaQuery("screen and (hover: none) and (pointer: coarse)")

  function whichDeviceSize(m,t,d){
    if ( m ) return 'mobile'
    if ( t ) return 'tablet'
    if ( d ) return 'desktop'
  }

  useEffect( () => {
    setDeviceSize(whichDeviceSize(isMobile,isTablet,isDesktop))
  },[isMobile,isTablet,isDesktop])

  return {deviceSize,isTouchScreen}
  
}