import React, { useState, useEffect } from 'react'
import { Routes,Route,Outlet,useParams,useLocation,Navigate,useNavigate,Link,redirect } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { useSessionHandshake } from './hooks/useSessionHandshake.js'
import { usePusherChannels } from './hooks/usePusherChannels.js'
import { useStore } from './hooks/useStore.jsx'
import { useDeviceInfo } from './hooks/useDeviceInfo.jsx'
import { EstablishingHandshake } from './components/organisms/EstablishingHandshake.jsx';
import { Login } from './components/pages/login/Login.jsx';
import { Header } from './components/organisms/header/Header.jsx'
import { Footer } from './components/organisms/footer/Footer.jsx'
import { ItineraryList, Itinerary } from './components/pages/itinerary'

import 'react-toastify/dist/ReactToastify.css';
import './App.scss'


function App() {
  const [count, setCount] = useState(0)

  const [handshake,execHandshake] = useSessionHandshake(true)
  const [sub,unsub,connected] = usePusherChannels(handshake)
  const [store,dispatch] = useStore()

  useEffect( () => {
    if ( handshake ){
      const { user } = handshake
      dispatch({type:'setUser', payload:{user}})
    }
  }, [handshake])

  if ( ! handshake ) return <EstablishingHandshake />

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={ <RequireAuth><Dashboard /></RequireAuth>} />
        <Route path="login" element={<Login />} />
        <Route path="account" element={<RequireAuth><div>account stuff</div></RequireAuth>} />
        <Route path="booking/:tripId" element={<RequireAuth><div>book a trip</div></RequireAuth>} />
        <Route path="itinerary" element={<RequireAuth><ItineraryList /></RequireAuth>} />
        <Route path="itinerary/:itineraryId" element={<RequireAuth><Itinerary /></RequireAuth>} />
      </Route>
    </Routes>
  )
}

function Dashboard(){

    return <Navigate to="/itinerary" />;
  return (
      <div><Link to="/itinerary">Itineraries</Link></div>
  )
}

function RequireAuth({ children }) {
  const [store] = useStore()
  let location = useLocation()

  if ( ! store.user.email ){
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

function Layout(){

  const {deviceSize} = useDeviceInfo()

  return (
    <div className={`site-wrapper ${deviceSize}`}>
      <Header />
      <div className="content-wrapper">
        <Outlet />
      </div>
      <Footer />
      <ToastContainer />
    </div>
  )
}

export default App
