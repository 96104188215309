import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom"
import { useSessionHandshake } from '../../../hooks/useSessionHandshake.js'
import { useApi } from '../../../hooks/useApi.jsx'
import { useStore } from '../../../hooks/useStore.jsx'
import './Login.styles.scss'

export function Login(){

  const { register, handleSubmit, watch, formState: { errors } } = useForm();

  const navigate = useNavigate()
  const location = useLocation()
  const [handshake, execHandshake] = useSessionHandshake(true)
  const [store,dispatch] = useStore()
  const [logInRes, execLogin] = useApi("login")

  useEffect( () => {
    if ( logInRes.isSuccess ){
      if ( logInRes.data.two_factor ){
        alert('This account requires two factor authentication but it is not yet supported.')
      } else {
        execHandshake()
      }
    }
  }, [logInRes])

  useEffect( () => {
    if ( handshake ){
      const { user } = handshake
      dispatch({type:'setUser', payload:{user}})
    }
  }, [handshake])

  useEffect( () => {
    if ( store.user.email ){
      navigate( location.state?.from ?? '/' )
    }
  }, [store.user, location])
  
  return (
    <div className="loginPage">
      <form onSubmit={ handleSubmit(execLogin) } className={`gti-box`}>
        <h2>Please Sign In</h2>
        { logInRes.isSuccess === false && <div className="simpleError">{logInRes.error.message}</div> }
        <div className="formGroup">
          <label>Email Address</label>
          <input type="email" {...register("email", { required: true })} />
          {/* {logInRes.error.errors.email && <span className="simpleError">{logInRes.error.errors.email}</span>}       */}
        </div>

        <div className="formGroup">
          <label>Password</label>
          <input type="password" {...register("password", { required: true })} />
          {/* {logInRes.error.errors.password && <span className="simpleError">{logInRes.error.errors.password}</span>}       */}
        </div>
        <button type="submit" disabled={logInRes.isLoading || logInRes.isSuccess}>Sign In</button>
        <p><a href="https://registration.gtitours.org/account/forgotpassword">Forgot your password?</a></p>
      </form>
    </div>
  )
}