import { useDeviceInfo } from "../../../hooks/useDeviceInfo.jsx"
import visaLogo from '../../../assets/img/visa.png'
import mastercardLogo from '../../../assets/img/mastercard.png'
import discoverLogo from '../../../assets/img/discover.png'
import amexLogo from '../../../assets/img/amex.png'
import './footer.styles.scss'

export function Footer(){

  const {deviceSize} = useDeviceInfo()

  return (
    <footer className={`${deviceSize}`}>
      <div className="container">
        <div className="cardLogos">
            <img src={visaLogo} />
            <img src={mastercardLogo} />
            <img src={discoverLogo} />
            <img src={amexLogo} />
        </div>
        <div>
          <a href="">GTI Tours Policies</a> | <a href="">Travel Insurance</a>
        </div>
        <div class="copyright">
          ©2022 All rights reserved.
        </div>
      </div>
    </footer>
  )
}