import { useState } from 'react';
import * as api from '../api/index.js'
import { toast } from 'react-toastify';

// inspired (and confused) by
// https://raw.githubusercontent.com/dgamboa/useapi-hook

export function useApi(apiFnName) {

  const [response, setResponse] = useState({
    isLoading: null,
    isSuccess: null,
    data: null,
    error: { code: null, message: null, errors: [] }
  })

  const fetchMethod = (...args) => {

    setResponse({
      isLoading: true,
      isSuccess: null,
      data: null,
      error: { code: null, message: null, errors: [] }
    });

    api[apiFnName](...args)
      .then(res => {
        setResponse( (currentResponse) => {
          return {
            ...currentResponse,
            isLoading: false,
            isSuccess: true,
            data: res.data,
            error: { code: null, message: null, errors: [] }
          }
        })
      })
      .catch(error => {
        if ( error.code === "ERR_NETWORK" ){
          console.log("AXIOS NETWORK ERROR",{error})
        } else if ( error?.response?.status === 419 ){
          // TODO: we could auto-re-fetch the csrf token
          alert('Session has expired. Please sign in again.')
          location.reload()
        } else {
          console.log("API Error",{error})
          toast.info('Error fetching itineraries.', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        setResponse( (currentResponse) => {
          return {
            ...currentResponse,
            isLoading: false,
            isSuccess: false,
            error: {
              code: error.response?.status ?? 'Unknown Status',
              message: error.response?.data?.message ?? error.message,
              errors: error.response?.data?.errors ?? []
            }
          }
        })
      })
  };

  return [response, fetchMethod];
}