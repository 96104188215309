import * as dateFns from 'date-fns'
import {formatInTimeZone} from "date-fns-tz";
import {parseISO} from "date-fns";

export function money(value){

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  return formatter.format(value)

}

export function date(value){
  const date = parseISO(value);
  return formatInTimeZone(date, 'UTC', 'LLL d, yyyy'); // Adjust format as needed
}

export function dateRange(d1,d2){
  return `${date(d1)} - ${date(d2)}`
}